
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        showError: false
      }
    },
    methods: {
      async applyOffer(offer) {
        this.showError = false
        const applied = await this.$api.addCoupon(offer.cred.toUpperCase())
        if (applied) {
          await this.$api.validateOrder()
          await this.$api.retrieveOrder()
        } else {
          this.showError = true
        }
      },
      async removeOffer(offer) {
        this.showError = false
        const removed = await this.$api.removeCoupon()
        if(removed) {
          await this.$api.validateOrder()
          await this.$api.retrieveOrder()
        } else {
          this.showError = true
        }
      },
    },
    computed: {
      ...mapState(['walletOffers','order','errors']),
    },
  }
