
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['loading', 'errors'])
  },
  data() {
    return {
      height: null
    }
  },
  async mounted() {

    this.checkChannel()

    if(!process.env.DISABLE_RECAPTCHA) {
      try {
        await this.$recaptcha.init()
      } catch(err) {
        console.error(err)
      }
    }
    this.$nextTick(function() {
      setTimeout(
        function() {
          this.height = this.mainHeight() // set min height
        }.bind(this),
        100
      )
    })
  },

  methods: {
    ...mapMutations([ 'setChannel' ]),

    async checkChannel() {
      if (window.location.hostname.includes('catering') || window.location.href.includes('2001')) {
        this.$store.commit('setChannel', 'catering')
      } else {
        this.$store.commit('setChannel', 'default')
      }

      console.log('href location: ', window.location.href)
      console.log('active channel: ', this.channel)
    },
  }
}
