import ZipField from '~/components/forms/ZipField'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      ZipField
    },
    library: {
      zip: {
        classification: 'zip',
        component: 'ZipField'
      }
    }
  })
}
