
import { mapState } from 'vuex'

export default {
  data() {
    return {
      orderType: '',
      timeIcon: '',
      orderTypes: [
        {
          slug: 'curbside',
          title: 'Curbside pickup',
          suffix: 'at',
          icon:
            '//storyblok.pleinaircdn.com/f/110020/x/7a68be6151/icon-curbside-pickup.svg',

          timeDialog: 'Pickup Time:'
        },
        {
          slug: 'pickup',
          title: 'In-Store pickup',
          suffix: 'at',
          icon:
            '//storyblok.pleinaircdn.com/f/110020/x/222f8488be/icon-store.svg',

          timeDialog: 'Pickup Time:'
        },
        {
          slug: 'delivery',
          title: 'Delivery',
          suffix: 'to',
          icon:
            '//storyblok.pleinaircdn.com/f/110020/x/ccf8e54297/icon-delivery.svg',

          timeDialog: ''
        },
        {
          slug: 'dispatch',
          title: 'Delivery',
          suffix: 'to',
          icon:
            '//storyblok.pleinaircdn.com/f/110020/x/ccf8e54297/icon-delivery.svg',

          timeDialog: ''
        }
      ]
    }
  },
  mounted() {
    if (process.browser) {
      this.orderType = this.orderTypes.find(
        (o) => o.slug === this.order.handoff
      )

      const clockIcon =
        '//storyblok.pleinaircdn.com/f/110020/x/b0c24a790a/icon-clock.svg'
      const calendarIcon =
        '//storyblok.pleinaircdn.com/f/110020/200x200/0a86aff5e1/bo_icons_flexibleschedule-1.png'
      const wantsToday = this.order.time.wanted
        ? this.$dayjs(this.order.time.wanted).isToday()
        : this.$dayjs(this.order.time.earliest).isToday()

      this.timeIcon = wantsToday ? clockIcon : calendarIcon

      this.$nextTick(() => {
        const orderConfirmModal = this.$refs.orderConfirmModal

        if (orderConfirmModal) {
          this.$refs.orderConfirmModal.focus()
        }
      })
    }
  },
  computed: {
    ...mapState(['order', 'location']),

    daypart() {
      let dp = false
      this.order.products.map((p) => {
        if (p.name.match(/10\:30/)) {
          dp = true
        }
      })
      return dp
    },
    earliest() {
      let earliest = this.$dayjs(this.order.time.earliest)
      if (this.daypart) {
        // get just the date from the earliest available time
        const eDay = earliest.format('YYYYMMDD')
        // get the timestamp for 10:30 on that date
        const eNew = this.$dayjs(`${eDay} 10:30`)
        // compare date @ 10:30 to date @ earliest time
        if (eNew > earliest) {
          // override earliest time with date @ 10:30
          earliest = eNew
        }
      }
      return earliest.format('YYYYMMDD HH:mm')
    }
  }
}
