// formulate.config.js
import { en } from '@braid/vue-formulate-i18n'
import NuxtLinkButton from '~/plugins/forms/NuxtLinkButton'
import zip from '~/plugins/forms/zip'
import phone from '~/plugins/forms/phone'
import storeSelect from '~/plugins/forms/storeSelect'
import PromoField from '~/plugins/forms/promoField'
import favs from '~/plugins/forms/favs'
import RichCheckbox from '~/plugins/forms/RichCheckbox'

export default {
  plugins: [
    en,
    NuxtLinkButton,
    zip,
    phone,
    storeSelect,
    PromoField,
    favs,
    RichCheckbox
  ],
  locale: 'en',
  useInputDecorators: false,
  classes: {
    outer: '',
    input(context) {

      switch (context.type) {
        case 'box':
          return 'rounded focus:outline-none border-gray-100 border-2  text-pepper focus:ring focus:ring-offset-0 focus:ring-gray-100 focus:ring-opacity-50'
        case 'nuxtlinkbutton':
          return 'text-center uppercase  mx-auto block hover:text-ketchup-dark font-bold transition-all duration-200 ease'
        case 'radio':
          return 'rounded-full focus:outline-none border-gray-100 border-2  text-pepper focus:ring focus:ring-offset-0 focus:ring-gray-100 focus:ring-opacity-50'
        case 'checkbox':
          return 'rounded mr-1 focus:outline-none border-gray-100 border-2 text-pepper focus:ring focus:ring-offset-0 focus:ring-gray-100 focus:ring-opacity-50'
        case 'button':
          return 'btn px-4 py-8 rounded-2xl text-lg text-white formulate-button w-full hover:bg-pepper hover:border-pepper transition-all duration-200 ease'
        case 'submit':
          return 'btn px-4 py-8 rounded-2xl text-lg text-white formulate-button w-full hover:bg-pepper hover:border-pepper transition-all duration-200 ease'
        case 'date':
          return 'border border-gray-100 rounded-lg px-6 py-6 leading-tight focus:border-pepper outline-none border-box w-full'
        default:
          return 'border border-gray-100 rounded-lg px-6 py-6 leading-none focus:border-pepper outline-none border-box w-full'
      }
    },
    label: 'font-body font-bold text-xl uppercase block',
    help: 'text-xs mb-1 text-pepper',
    error: 'text-red-700 mb-1'
  },
  rules: {
    foobar: ({ value }) => ['foo', 'bar'].includes(value)
  }
}
