import { render, staticRenderFns } from "./RichTextRenderReplacer.vue?vue&type=template&id=5cecdbfd&scoped=true&"
import script from "./RichTextRenderReplacer.vue?vue&type=script&lang=js&"
export * from "./RichTextRenderReplacer.vue?vue&type=script&lang=js&"
import style0 from "./RichTextRenderReplacer.vue?vue&type=style&index=0&id=5cecdbfd&prod&scoped=true&lang=css&"
import style1 from "./RichTextRenderReplacer.vue?vue&type=style&index=1&id=5cecdbfd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cecdbfd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichTextRenderReplacerContent: require('/opt/build/repo/components/common/RichTextRenderReplacerContent.vue').default})
