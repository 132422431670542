export default (context, inject) => {
  const jobs = {
    async request(obj, verify = true, surpress = false) {
      obj.url = `${window.location.origin}/api/jobsearch`
      if(process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      context.store.commit('setErrors', [])
      context.store.commit('setLoading', true)
      return context
        .$axios(obj)
        .then((res) => {
          context.store.commit('setLoading', false)
          return res.data
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if (surpress === false) {
            if (Array.isArray(err.response.data)) {
              if(typeof err.response.data[0] === 'string') {
                context.store.commit('setErrors', err.response.data)
              } else {
                let errors = []
                err.response.data.map(e => {
                  errors.push(e.message)
                })
                context.store.commit('setErrors', errors)
              }
            } else if (err.response.data.message) {
              context.store.commit('setErrors', [err.response.data.message])
            } else {
              context.store.commit('setErrors', [
                'Connection issue. Please try again.'
              ])
            }
          }
          return null
        })
    },
    async searchJobs(params) {
      return await this.request({
        method: 'get',
        headers: {},
        params
      })
    },
    async getJob(params) {
      return await this.request({
        method: 'get',
        headers: {},
        params
      })
    }
  }

  inject('jobs', jobs)
  context.$jobs = jobs
}
