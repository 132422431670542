
import { mapGetters } from 'vuex'

export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      locations: []
    }
  },
  computed: {
    ...mapGetters(['sortedLocations'])
  },
  mounted() {
    this.locations = this.sortedLocations || []
  },
  methods: {
    limitText(count) {
      return `and ${count} other locations`
    },
    clearAll() {
      this.locations = []
    }
  }
}
