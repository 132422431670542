
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      favs: []
    }
  },

  methods: {
    limitText(count) {
      return `and ${count} other favs`
    },

    asyncFind(query) {
      // TODO: hookup to api to find fav items
    },

    clearAll() {
      this.favs = []
    }
  }
}
