import RichCheckbox from '~/components/forms/RichCheckbox'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      RichCheckbox
    },
    library: {
      richcheckbox: {
        classification: 'richcheckbox',
        component: 'RichCheckbox'
      }
    }
  })
}
