export const actions = {
  // these functions can be mapped out with the Dynamic Form Component
  async signup({ commit }, payload) {

    try {
      const user = await this.$api.createUser(payload)

      if (user) {
        if (this.$store.state.redirect) {
          this.$router.push(this.$store.state.redirect)
        } else {
          this.$router.push('/account')
        }
      }
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async login({ commit }, payload) {

    try {
      await this.$api.loginUser(payload)
      const user = await this.$api.getUser()

      if (user) {
        if (this.$store.state.redirect) {
          this.$router.push(this.$store.state.redirect)
        } else {
          this.$router.push('/account')
        }
      }
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async findlocation({ commit }, payload) {

    try {
      return await this.$api.findLocations(payload)
    } catch (error) {
      console.error(error)
      return error
    }
  }
}
