export default (context, inject) => {
  const api = {
    async request(
      obj,
      verify = false,
      surpress = false,
      loader = true,
      respond = false
    ) {
      obj.url = `${window.location.origin}/api/v1/${obj.headers.path}`
      delete obj.headers.path
      if (process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
        obj.headers.verify_transition = true
      }
      if (process.env.GW_ENV) {
        obj.headers.env = process.env.GW_ENV
      }
      if (context.store.state.deviceId) {
        obj.headers.device_id = context.store.state.deviceId
      }
 
      if (window.location.origin.includes('catering') || window.location.origin.includes('2001')) {
        obj.headers.client_sub = 'catering'
      }

      obj.headers.client_type = context.store.state.client
      obj.headers.version = 2
      context.store.commit('setErrors', [])
      if (loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          context.store.commit('setLoading', false)
          if (respond) {
            return {
              status: res.status,
              data: res.data
            }
          } else {
            return res.data
          }
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if (respond === true) {
            return {
              status: err.response.status,
              data: err.response.data
            }
          } else {
            if (surpress === false) {
              if (Array.isArray(err.response.data)) {
                if (typeof err.response.data[0] === 'string') {
                  context.store.commit('setErrors', err.response.data)
                } else if (typeof err.response.data[0] === 'object') {
                  context.store.commit('setErrors', [
                    err.response.data[0].message
                  ])
                } else {
                  const errors = []
                  err.response.data.map((e) => {
                    errors.push(e.message)
                  })
                  context.store.commit('setErrors', errors)
                }
              } else if (err.response.data.message) {
                context.store.commit('setErrors', [err.response.data.message])
              } else {
                context.store.commit('setErrors', [
                  'Connection issue. Please try again.'
                ])
              }
            }
            return null
          }
        })
    },
    async subscribe(customer) {
      const subscription = await this.request({
        method: 'post',
        headers: {
          path: 'eclub/subscribe'
        },
        data: customer
      })
      return subscription
    },
    async createUser(customer) {
      const user = await this.request(
        {
          method: 'post',
          headers: {
            path: 'members/create'
          },
          data: customer
        },
        true
      )
      if (user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginUser(creds) {
      const user = await this.request(
        {
          method: 'post',
          headers: {
            path: 'members/signin'
          },
          data: creds
        },
        true,
        true,
        true,
        true
      )
      if (user.status == 200) {
        context.store.commit('setToken', user.data.tokens)
      }
      return user
    },
    async loginSSO(creds) {
      const user = await this.request(
        {
          method: 'post',
          headers: {
            path: 'members/sso'
          },
          data: creds
        },
        false,
        true,
        true,
        true
      )
      if (user.status == 200) {
        context.store.commit('setToken', user.data.tokens)
      }
      return user
    },
    async logoutUser() {
      await this.request({
        method: 'delete',
        headers: {
          path: 'members/signout',
          token: context.store.state.token.access.token
        }
      })
      context.store.commit('setUser', null)
      context.store.commit('setToken', null)
      await this.abandonOrder()
      return true
    },
    async getUser() {
      const user = await this.request({
        method: 'get',
        headers: {
          path: 'members/get',
          token: context.store.state.token.access.token
        }
      })
      if (user) {
        if (user.phone && user.phone.match(/^\+?1/)) {
          user.phone = user.phone.replace(/^\+?1/, '')
        }
        context.store.commit('setUser', user)
      }
      return user
    },
    async getLoyalty() {
      const user = await this.request({
        method: 'get',
        headers: {
          path: 'members/loyalty',
          token: context.store.state.token.access.token
        }
      })
      return user
    },
    async updateUser(d) {
      const user = await this.request({
        method: 'put',
        headers: {
          path: 'members/update',
          token: context.store.state.token.access.token
        },
        data: d
      })
      if (user) {
        const newUser = JSON.parse(JSON.stringify(context.store.state.user))
        newUser.first_name = user.first_name
        newUser.last_name = user.last_name
        newUser.email = user.email
        newUser.phone = user.phone
        newUser.optin = user.optin
        newUser.optin_sms = user.optin_sms
        context.store.commit('setUser', newUser)
      }
      return user
    },
    async getFaveLocations() {
      const faves = await this.request({
        method: 'get',
        headers: {
          path: 'members/faves/locations',
          token: context.store.state.token.ordering.access.token
        }
      })
      if (faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    async setFaveLocation(id) {
      const faves = await this.request(
        {
          method: 'post',
          headers: {
            path: `members/faves/locations/${id}`,
            token: context.store.state.token.ordering.access.token
          }
        },
        false,
        false,
        false
      )
      if (faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    async deleteFaveLocation(id) {
      const faves = await this.request(
        {
          method: 'delete',
          headers: {
            path: `members/faves/locations/${id}`,
            token: context.store.state.token.ordering.access.token
          }
        },
        false,
        false,
        false
      )
      if (faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    // async setFaveLocation(id) {
    //   const fave = await this.request({
    //     method: 'post',
    //     headers: {
    //       path: `members/faves/locations/${id}`,
    //       token: context.store.state.token.ordering.access.token
    //     }
    //   })
    //   return fave
    // },
    // async deleteFaveLocation(id) {
    //   const fave = await this.request({
    //     method: 'delete',
    //     headers: {
    //       path: `members/faves/locations/${id}`,
    //       token: context.store.state.token.ordering.access.token
    //     }
    //   })
    //   if(fave != null) {
    //     const user = JSON.parse(JSON.stringify(context.store.state.user))
    //     user.favelocations.map((f, i) => {
    //       if(f.menu_id == id) {
    //         user.favelocations.splice(i, 1)
    //       }
    //     })
    //     context.store.commit('setUser', user)
    //   }
    //   return fave
    // },
    async getPayments() {
      const payments = await this.request({
        method: 'get',
        headers: {
          path: `members/payments`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if (payments) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments = payments.payments
        context.store.commit('setUser', user)
      }
      return payments
    },
    async deletePayment(pid) {
      const payments = await this.request({
        method: 'delete',
        headers: {
          path: `members/payments/${pid}`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if (payments != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments.map((p, i) => {
          if (p.account.id == pid) {
            user.payments.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return payments
    },
    async getAddresses() {
      const addresses = await this.request({
        method: 'get',
        headers: {
          path: `members/addresses`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if (addresses) {
        // remove duplicates
        const unique = []
        const added = []
        const check = []
        for (let i = 0; i < addresses.addresses.length; i++) {
          if (addresses.addresses[i].default) {
            // first add default address
            unique.push(addresses.addresses[i])
            check.push({
              uid:
                addresses.addresses[i].address +
                addresses.addresses[i].building +
                addresses.addresses[i].city,
              address: addresses.addresses[i]
            })
          }
        }
        for (let i = 0; i < addresses.addresses.length; i++) {
          if (!addresses.addresses[i].default) {
            // skip default, it was already added
            check.push({
              uid:
                addresses.addresses[i].address +
                addresses.addresses[i].building +
                addresses.addresses[i].city,
              address: addresses.addresses[i]
            })
          }
        }
        for (let i = 0; i < check.length; i++) {
          if (!added.includes(check[i].uid)) {
            added.push(check[i].uid)
            if (!check[i].address.default) {
              unique.push(check[i].address)
            }
          }
        }
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.addresses = unique
        context.store.commit('setUser', user)
      }
    },
    async deleteAddress(aid) {
      const addresses = await this.request({
        method: 'delete',
        headers: {
          path: `members/addresses/${aid}`,
          token: context.store.state.token.ordering.access.token
        }
      })

      const user = JSON.parse(JSON.stringify(context.store.state.user))
      user.addresses.map((a, i) => {
        if (a.id == aid) {
          user.addresses.splice(i, 1)
        }
      })
      context.store.commit('setUser', user)
      return addresses
    },
    async changePassword(newpassword, oldpassword) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/password',
          token: context.store.state.token.access.token
        },
        data: {
          new: newpassword,
          current: oldpassword
        }
      })
    },
    async forgotPassword(email) {
      return await this.request(
        {
          method: 'post',
          headers: {
            path: 'members/forgotpassword'
          },
          data: {
            email
          }
        },
        true
      )
    },
    async resetPassword(token, email, password) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/resetpassword'
        },
        data: {
          token,
          email,
          password
        }
      })
    },
    async findCateringLocations() {
      const locations = await this.request({
        method: 'get',
        headers: {
          path: 'locations'
        },
      })
      if (locations) {
        if (locations.count > 0) {
          context.store.commit('setLocationList', locations.locations)
        } else {
          context.store.commit('setLocationList', false)
        }
        context.store.commit('setMiniLoading', false)
      } else {
        context.store.commit('setLocationList', null)
        context.store.commit('setMiniLoading', false)
      }
      return locations
    },
    async findLocations(loc) {
      const locations = await this.request({
        method: 'get',
        headers: {
          path: 'locations/search'
        },
        params: loc
      })
      if (locations) {
        if (locations.count > 0) {
          context.store.commit('setLocationList', locations.locations)
        } else {
          context.store.commit('setLocationList', false)
        }
        context.store.commit('setMiniLoading', false)
      } else {
        context.store.commit('setLocationList', null)
        context.store.commit('setMiniLoading', false)
      }
      return locations
    },
    async findDeliveryLocations(data) {
      const locations = await this.request({
        method: 'post',
        headers: {
          path: 'locations/delivery'
        },
        data
      })
      return locations
    },
    async getLocation(id, byMenuId = false, setLocation = true, loader = true) {
      const request = {
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      }
      if (byMenuId) {
        request.params = {
          byMenuId: true
        }
      }
      const location = await this.request(request, false, false, loader)
      if (location && setLocation) {
        context.store.commit('setLocation', location)
      }
      return location
    },
    async getMenu(loader = true, config = { menuId: null, setMenu: true }) {
      const menuId = config.menuId || context.store.state.location.menu_id

      const menu = await this.request(
        {
          method: 'get',
          headers: {
            path: `menus/${menuId}`
          }
        },
        false,
        false,
        loader
      )
      if (menu) {
        const menuCopy = JSON.parse(JSON.stringify(menu))
        menuCopy.categories = []
        menu.categories.map((c) => {
          // if(c.name.toLowerCase() != 'app offer') {
          menuCopy.categories.push(c)
          // }
        })

        if (config.setMenu) {
          context.store.commit('setMenu', menuCopy)
        } else {
          return menuCopy
        }
        
        // reset order if changing locations
        // TODO: transfer order to new location
        // context.store.commit('setOrder', null)
        return true
      } else {
        return false
      }
    },
    getProduct(pid) {
      if (context.store.state.location) {
        return this.request({
          method: 'get',
          headers: {
            path: `menus/${context.store.state.location.menu_id}/products/${pid}`
          }
        })
      } else {
        redirect('/menu/items/' + pid)
      }
    },
    async getRecentOrders() {
      const orders = await this.request({
        methods: 'get',
        headers: {
          path: 'orders/recent',
          token: context.store.state.token.ordering.access.token
        }
      })
      return orders
    },
    async createReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async getFaveOrders() {
      const orders = await this.request({
        method: 'get',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        }
      })
      return orders
    },
    async createFaveOrder(id, name) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id,
          description: name
        }
      })
      return order
    },
    async createFaveReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async createOrder() {
      let order = null
      const headers = {
        path: 'orders/create'
      }
      if (context.store.state.token) {
        headers.token = context.store.state.token.ordering.access.token
      }
      order = await this.request({
        method: 'post',
        headers,
        data: {
          menu_id: context.store.state.location.menu_id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async abandonOrder() {
      if (context.store.state.order) {
        await this.request({
          method: 'delete',
          headers: {
            path: `orders/${context.store.state.order.id}/abandon`
          }
        })
        context.store.commit('setOrder', null)
        return true
      } else {
        return false
      }
    },
    async transferOrder() {
      let order = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/transfer`
        },
        data: {
          menu_id: context.store.state.location.menu_id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async retrieveOrder() {
      const order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${context.store.state.order.id}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async retrieveMobileOrder(id) {
      const order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${id}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addToOrder(prods) {
      let createOrder = true
      if (!context.store.state.order) {
        createOrder = await this.createOrder(
          context.store.state.location.menu_id
        )
      }
      if (createOrder) {
        const order = await this.request(
          {
            method: 'post',
            headers: {
              path: `orders/${context.store.state.order.id}/products`
            },
            data: {
              products: prods
            }
          },
          false,
          false,
          false
        )
        if (order) {
          context.store.commit('setOrder', order)
        }
        return order
      } else {
        return null
      }
    },
    async updateInOrder(prods) {
      const order = await this.request(
        {
          method: 'put',
          headers: {
            path: `orders/${context.store.state.order.id}/products`
          },
          data: {
            products: prods
          }
        },
        false,
        false,
        false
      )
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeFromOrder(pid) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/products/${pid}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async validateOrder() {
      const totals = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/validate`
        }
      })
      if (totals) {
        const order = JSON.parse(JSON.stringify(context.store.state.order))
        order.taxes = totals.taxes
        order.totals.donations = totals.totals.donations
        order.totals.tax = totals.totals.tax
        order.totals.total = totals.totals.total
        context.store.commit('setOrder', order)
        totals.billing.map((b) => {
          if (b.type == 'creditcard' && b.accounts.length > 0) {
            context.store.commit('setPayments', b.accounts)
          }
        })
      }
      return totals
    },
    async setHandoff(mode) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/handoff/${mode}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setAddress(type, address) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/address`
        },
        data: {
          type,
          address
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setCustomFields(fields) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/custom`
        },
        data: {
          fields
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getCalendar(from, to) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/times`
        },
        params: {
          from,
          to
        }
      })
    },
    async setTime(time) {
      try {
        const order = await this.request({
          method: 'put',
          headers: {
            path: `orders/${context.store.state.order.id}/time`
          },
          data: {
            time
          }
        })
        if (order) {
          context.store.commit('setOrder', order)
        }
        return order
      } catch (error) {
        // Handle errors if the request fails
        console.error('Error setting time:', error)
        throw error // Optionally re-throw the error to propagate it up
      }
    },
    // async setTime(time) {
    //   const order = await this.request({
    //     method: 'put',
    //     headers: {
    //       path: `orders/${context.store.state.order.id}/time`
    //     },
    //     data: {
    //       time
    //     }
    //   })
    //   if (order) {
    //     context.store.commit('setOrder', order)
    //   }
    //   return order
    // },
    async deleteTime() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addTip(amount) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/tip`
        },
        data: {
          amount
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addCoupon(code) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        },
        data: {
          code
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeCoupon() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async contact(form, request) {
      return await this.request(
        {
          method: 'post',
          headers: {
            path: 'contact'
          },
          data: {
            form,
            request
          }
        },
        true,
        false,
        true,
        true
      )
    },
    getBasketToken() {
      const headers = {
        path: `orders/${context.store.state.order.id}/token`
      }
      if (context.store.state.token) {
        headers.token = context.store.state.token.access.token
      }
      return this.request({
        method: 'post',
        headers
      })
    },
    submitOrder(billing) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/submit`,
          token: context.store.state.token.ordering.access.token
        },
        data: {
          usertype: 'user',
          payments: billing
        }
      })
    },
    trackOrder(oid, cid, products) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          id: cid,
          products
        }
      })
    },
    trackPaymentFailure(oid, error, details) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          error,
          type: 'ccsf',
          details
        }
      })
    },
    setArrival(id) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${id}/arrival`
        }
      })
    },
    getOrderStatus(id) {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${id}/status`
        }
      })
    },
    cancelOrder(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${id}`
        }
      })
    },
    getOffers() {
      return this.request({
        method: 'get',
        headers: {
          path: `members/offers/all`
        }
      })
    },
    getWalletOffers() {
      return this.request(
        {
          method: 'get',
          headers: {
            path: `members/balance?memberID=${context.store.state.token.id}`,
            token: context.store.state.token.access.token
          }
        },
        false,
        false,
        false
      )
    },
    redeemOfferToAccount(id, channel, campaign) {
      return this.request(
        {
          method: 'post',
          headers: {
            path: `members/redeem?memberID=${context.store.state.token.id}`,
            token: context.store.state.token.access.token
          },
          data: {
            id,
            channel,
            campaign
          }
        },
        false,
        false,
        false
      )
    },
    getOffersForOrder() {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards`,
          token: context.store.state.token.access.token
        }
      })
    },
    applyOfferToOrder(id, memberid, reference) {
      return this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards`,
          token: context.store.state.token.access.token
        },
        data: {
          memberid,
          references: [reference]
        }
      })
    },
    removeOfferFromOrder(id, memberid, reference) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards/${id}`,
          token: context.store.state.token.access.token
        },
        data: {
          memberid,
          references: [reference]
        }
      })
    },
    getUpsellsForCart() {
      if (context.store.state.order) {
        const request = {
          method: 'get',
          headers: {
            path: `upsells/${context.store.state.order.id}`
          }
        }
        if (context.store.state.user) {
          request.headers.params = {
            user: context.store.state.user.email
          }
        }
        return this.request(request, false, true, false)
      } else {
        return null
      }
    },
    getUpsellsForProduct(sku) {
      if (context.store.state.order) {
        const request = {
          method: 'get',
          headers: {
            path: `upsells/${context.store.state.order.id}/product/${sku}`
          }
        }
        if (context.store.state.user) {
          request.headers.params = {
            user: context.store.state.user.email
          }
        }
        return this.request(request, false, true, false)
      } else {
        return null
      }
    },

    //BRAZE FUNCTION

    async brazeRequest(
      obj,
      surpress = false,
      loader = true,
      respond = false
    ) {
      obj.url = `${window.location.origin}/api/braze/${obj.headers.path}`
      delete obj.headers.path
      
      context.store.commit('setErrors', [])
      if (loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          context.store.commit('setLoading', false)
          if (respond) {
            return {
              status: res.status,
              data: res.data
            }
          } else {
            return res.data
          }
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if (respond === true) {
            return {
              status: err.response.status,
              data: err.response.data
            }
          } else {
            if (surpress === false) {
              if (Array.isArray(err.response.data)) {
                if (typeof err.response.data[0] === 'string') {
                  context.store.commit('setErrors', err.response.data)
                } else if (typeof err.response.data[0] === 'object') {
                  context.store.commit('setErrors', [
                    err.response.data[0].message
                  ])
                } else {
                  const errors = []
                  err.response.data.map((e) => {
                    errors.push(e.message)
                  })
                  context.store.commit('setErrors', errors)
                }
              } else if (err.response.data.message) {
                context.store.commit('setErrors', [err.response.data.message])
              } else {
                context.store.commit('setErrors', [
                  'Connection issue. Please try again.'
                ])
              }
            }
            return null
          }
        })
    },
    async usersTrack(trackData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'users/track'
        },
        data: trackData
      },
      false, 
      false, 
      true)
      return response
    },
    async usersCreateAlias(userAliases){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'users/alias/new',
        },
        data: userAliases
      },
      false, 
      false,
      true)
      return response
    },
    async usersIdentify(aliases){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'users/identify'
        },
        data: aliases
      },
      false, 
      false, 
      true)
      return response
    },
    async exportCampaignAnalytics(campaign_identifier){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `campaigns/data_series?campaign_id=${campaign_identifier}&length=7`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportCampaignDetails(campaign_identifier){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `campaigns/details?campaign_id=${campaign_identifier}`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportCanvasSeriesAnalytics(canvas_identifier, endingAt){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `canvas/data_series?canvas_id=${canvas_identifier}&ending_at=${endingAt}`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportCanvasAnalyticsSummary(canvas_identifier, endingAt){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `canvas/data_summary?canvas_id=${canvas_identifier}&ending_at=${endingAt}`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportSegmentList(){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: 'segments/list'
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportSegmentAnalytics(segmentId){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `segments/data_series?segment_id=${segmentId}&length=14`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportSegmentDetails(segmentId){
      const response = await this.brazeRequest({
        method: 'get',
        headers: {
          path: `segments/details?segment_id=${segmentId}`
        }
      },
      false, 
      false,
      true)
      return response
    },
    async exportUserProfileById(idData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'users/export/ids'
        },
        data: idData
      },
      false, 
      false,
      true)
      return response
    },
    async exportUserProfileBySegment(segmentData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'users/export/segment'
        },
        data: segmentData
      },
      false, 
      false,
      true)
      return response
    },
    async messagesSend(sendData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'messages/send'
        },
        data: sendData
      },
      false, 
      false,
      true)
      return response
    },
    async messagesSendCampaign(campaignData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'campaigns/trigger/send'
        },
        data: campaignData
      },
      false, 
      false,
      true)
      return response
    },
    async messagesSendCanvas(canvasData){
      const response = await this.brazeRequest({
        method: 'post',
        headers: {
          path: 'canvas/trigger/send'
        },
        data: canvasData
      },
      false, 
      false,
      true)
      return response
    }
  }

  inject('api', api)
  context.$api = api
}
