import { render, staticRenderFns } from "./CartItem.vue?vue&type=template&id=ed1f2184&scoped=true&"
import script from "./CartItem.vue?vue&type=script&lang=js&"
export * from "./CartItem.vue?vue&type=script&lang=js&"
import style0 from "./CartItem.vue?vue&type=style&index=0&id=ed1f2184&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed1f2184",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconEdit: require('/opt/build/repo/components/icons/IconEdit.vue').default,IconTrash: require('/opt/build/repo/components/icons/IconTrash.vue').default,IconSubtract: require('/opt/build/repo/components/icons/IconSubtract.vue').default,IconAdd: require('/opt/build/repo/components/icons/IconAdd.vue').default})
