import FavoriteItem from '~/components/forms/FavoriteItem'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      FavoriteItem
    },
    library: {
      favs: {
        classification: 'favs',
        component: 'FavoriteItem'
      }
    }
  })
}
