import Vue from 'vue'

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import Transitions from 'vue2-transitions'
Vue.use(Transitions)

import VueMarkdown from 'vue-markdown'
Vue.use(VueMarkdown)
Vue.component('vue-markdown', VueMarkdown)

import VueNumberInput from '@chenfengyuan/vue-number-input'
Vue.component('vue-number-input', VueNumberInput)

import VueMask from 'v-mask'
Vue.use(VueMask)