import NuxtLinkButton from '~/components/forms/NuxtLinkButton'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      NuxtLinkButton
    },
    library: {
      nuxtlinkbutton: {
        classification: 'nuxtlinkbutton',
        component: 'NuxtLinkButton'
      }
    }
  })
}
