
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        showPromoForm: false,
        promo: '',
        error: '',
        status: ''
      }
    },
    mounted() {
      if (this.order && this.order.discounts.length) {
        if (this.order.discounts[0].type == 'Coupon') {
          this.promo = this.order.coupon.couponcode
          this.status = 'approved'
        }
      }
    },
    computed: {
      ...mapState(['errors','order']),
    },
    methods: {
      async handleSumbit() {
        try {
          if (this.status !== 'approved') {
            const valid = await this.$api.addCoupon(this.promo.toUpperCase())
            valid ? (this.status = 'approved') : (this.status = 'failed')
            if (valid) {
              this.error = ''
            }
            if (this.errors && this.errors[0] == '[200] Code is invalid') {
              this.error =  'Error: Invalid promo code.'
            }
            if (this.errors && this.errors[0] == 'Coupon code cannot be empty.') {
              this.error = 'Error: Promo code cannot be empty.'
            }
            if (this.errors && this.errors[0] == 'Offer not valid - $200.00 Minimum Required') {
              this.error = 'Error: Offer not valid - $200.00 Minimum Required'
            }
            if (this.errors && this.errors[0] == 'Code is currently in use on another transaction') {
              this.error = 'Error: Promo code is currently in use on another transaction'
            }
          } else {
            this.removePromo()
          }
        } catch (error) {
          this.status = 'failed'
        }
      },
      async removePromo() {
        await this.$api.removeCoupon()
        this.promo = ''
        this.status = ''
      },
    }
  }
