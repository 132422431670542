
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        upsells: [],
        added: [],
        imgPath: process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod' ? 'https://olo-images-live.imgix.net/' : 'https://olo-images-sandbox.imgix.net/',
      }
    },
    computed: {
      ...mapState(['menu', 'order']),
    },
    async mounted() {
      this.upsells = await this.$api.getUpsellsForCart()
    },
    methods: {
      async getUpsells() {
        this.upsells = await this.$api.getUpsellsForCart()
      },
      getImage(upsell) {
        const product = this.menu.categories
          .flatMap(category => category.products)
          .find(product => product.id === upsell.id)
        return product 
          ? `${this.imgPath}${product.images?.find(img => img.groupname === 'mobile-webapp-menu')?.filename}` 
          : null
      },
      open(upsell) {
        const product = this.menu.categories
          .flatMap(category => category.products)
          .find(product => product.id === upsell.id)
        this.$store.commit('setShowCart', false)
        this.$router.push(`/order/product?id=${product.sku}`)
        if (this.$route.name == 'order-product') {
          setTimeout(() => {
            this.$router.go(`/order/product?id=${product.sku}`)
          },100)
        }
      },
      // getImage(upsell) {
      //   let image = null
      //   this.menu.categories.forEach(category => {
      //     category.products.forEach(product => {
      //       if (product.id == upsell.id) {
      //         image = this.imgPath+product.images?.find((n) => {
      //           return n.groupname === 'mobile-webapp-menu'
      //         }).filename
      //       }
      //     })
      //   })
      //   return image
      // },
      // customize(item) {
      //   const currentId = this.$route.query.id
      //   const newId = item.sku
      //   if (currentId !== newId) {
      //     const link = {
      //       path: '/order/product',
      //       query: {
      //         id: newId
      //       }
      //     };
      //     console.log(link)
      //     this.$router.push(link)

      //     if (this.$route.name == 'order-product') {
      //       setTimeout(() => {
      //         this.$router.go(link)
      //       },250)
      //     }
      //     this.$store.commit('setShowCart', false)
      //   }
      // },
      // productImage(item) {
      //   if (item.images === null) return this.defaultImg
      //   try {
      //     const img = item.images.find((n) => {
      //       return n.groupname === 'marketplace-product'
      //     }).filename
      //     return img ? `${this.imgPath}${img}` : this.defaultImg
      //   } catch (err) {
      //     console.error(err)
      //     return this.defaultImg
      //   }
      // }
    },
    watch: {
      'order.products': {
        handler(newVal, oldVal) {
          if (newVal.length !== oldVal.length) {
            // Refresh upsells if an item is removed or added
            this.getUpsells()
          }
        },
        deep: true
      }
    }
  }
