import PromoCodeField from '~/components/forms/PromoCodeField'

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      PromoCodeField
    },
    library: {
      promo: {
        classification: 'promo',
        component: 'PromoCodeField'
      }
    }
  })
}
