
  export default {
    props: {
      error: {
        type: Object,
        default: () => {}
      }
    },
    layout: 'default', // you can set a custom layout for the error page
    head() {
      return this.$metadata()
    },
    computed: {
      currentURL() {
        return this.$route.fullPath
      }
    }
  }
