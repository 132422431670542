import StoreSelect from '~/components/forms/StoreSelect'

export default (formulateInstance) => {
  formulateInstance.extend({
    components: {
      StoreSelect
    },
    library: {
      location: {
        classification: 'location',
        component: 'StoreSelect'
      }
    }
  })
}
