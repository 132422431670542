
import { f } from 'vue-slick-carousel'

export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    textContent() {
      if(this.content.content?.length > 0) {
        return this.content.content[0].text
      } else {
        return ''
      }
    },
  },
  methods: {
    attrs(t) {
      let textClass = ''
      if(t.marks) {
        t.marks.map(m => {
          if(m.type === 'bold') {
            textClass += 'font-bold '
          } else if(m.type === 'italic') {
            textClass += 'italic '
          }
        })
      }
      return textClass
    },
    getLink(t) {
      let link = null
      if(t.marks) {
        t.marks.map(m => {
          if(m.type === 'link') {
            link = m.attrs.href
          }
        })
      }
      return link
    }
  }
}
