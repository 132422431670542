import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7311cc54 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _484494f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e5333d36 = () => interopDefault(import('../pages/biscuit-month/index.vue' /* webpackChunkName: "pages/biscuit-month/index" */))
const _37c1db88 = () => interopDefault(import('../pages/bo-time-moments/index.vue' /* webpackChunkName: "pages/bo-time-moments/index" */))
const _3957697e = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _0bea9cda = () => interopDefault(import('../pages/expansion-menu/index.vue' /* webpackChunkName: "pages/expansion-menu/index" */))
const _6a3618dc = () => interopDefault(import('../pages/in-the-community/index.vue' /* webpackChunkName: "pages/in-the-community/index" */))
const _7f6ea985 = () => interopDefault(import('../pages/media-resources/index.vue' /* webpackChunkName: "pages/media-resources/index" */))
const _5b71908c = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _7c89b5b4 = () => interopDefault(import('../pages/news-and-community/index.vue' /* webpackChunkName: "pages/news-and-community/index" */))
const _2cb54b78 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _2c62ff52 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _42676b73 = () => interopDefault(import('../pages/positions/index.vue' /* webpackChunkName: "pages/positions/index" */))
const _4482e2e5 = () => interopDefault(import('../pages/refund/index.vue' /* webpackChunkName: "pages/refund/index" */))
const _1fb125d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _9834cfb0 = () => interopDefault(import('../pages/technical-support/index.vue' /* webpackChunkName: "pages/technical-support/index" */))
const _64bd03af = () => interopDefault(import('../pages/about/chicken-scoop/index.vue' /* webpackChunkName: "pages/about/chicken-scoop/index" */))
const _888cdd76 = () => interopDefault(import('../pages/account/complete.vue' /* webpackChunkName: "pages/account/complete" */))
const _20eed6ac = () => interopDefault(import('../pages/account/inbox.vue' /* webpackChunkName: "pages/account/inbox" */))
const _11ec380d = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _40619a00 = () => interopDefault(import('../pages/account/oauthcallback.vue' /* webpackChunkName: "pages/account/oauthcallback" */))
const _2ebd308e = () => interopDefault(import('../pages/account/order-history.vue' /* webpackChunkName: "pages/account/order-history" */))
const _150c92a9 = () => interopDefault(import('../pages/account/password-reset.vue' /* webpackChunkName: "pages/account/password-reset" */))
const _90d3558e = () => interopDefault(import('../pages/account/payments.vue' /* webpackChunkName: "pages/account/payments" */))
const _156e9b36 = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _17f8d1a2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _77f4b6a4 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _58d33585 = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _2fd77c50 = () => interopDefault(import('../pages/app/confirm.vue' /* webpackChunkName: "pages/app/confirm" */))
const _77bc7a1e = () => interopDefault(import('../pages/app/payment.vue' /* webpackChunkName: "pages/app/payment" */))
const _fdb32e8c = () => interopDefault(import('../pages/app/signin.vue' /* webpackChunkName: "pages/app/signin" */))
const _21a7f56a = () => interopDefault(import('../pages/expansion-menu/items/index.vue' /* webpackChunkName: "pages/expansion-menu/items/index" */))
const _7af3a15c = () => interopDefault(import('../pages/menu/nutrition.vue' /* webpackChunkName: "pages/menu/nutrition" */))
const _497044ae = () => interopDefault(import('../pages/menu/product.vue' /* webpackChunkName: "pages/menu/product" */))
const _519af2a7 = () => interopDefault(import('../pages/messaging/test.vue' /* webpackChunkName: "pages/messaging/test" */))
const _c7a1a85e = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _98d05ff6 = () => interopDefault(import('../pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _4186aec0 = () => interopDefault(import('../pages/order/location.vue' /* webpackChunkName: "pages/order/location" */))
const _462806b4 = () => interopDefault(import('../pages/order/product.vue' /* webpackChunkName: "pages/order/product" */))
const _5f987576 = () => interopDefault(import('../pages/order/type.vue' /* webpackChunkName: "pages/order/type" */))
const _5723fbc6 = () => interopDefault(import('../pages/positions/info.vue' /* webpackChunkName: "pages/positions/info" */))
const _63066267 = () => interopDefault(import('../pages/about/chicken-scoop/_slug.vue' /* webpackChunkName: "pages/about/chicken-scoop/_slug" */))
const _6ae2806b = () => interopDefault(import('../pages/expansion-menu/items/_item.vue' /* webpackChunkName: "pages/expansion-menu/items/_item" */))
const _767f0ee4 = () => interopDefault(import('../pages/about/_slug.vue' /* webpackChunkName: "pages/about/_slug" */))
const _468df3ae = () => interopDefault(import('../pages/account/_slug.vue' /* webpackChunkName: "pages/account/_slug" */))
const _3b2f1e18 = () => interopDefault(import('../pages/bo-time-moments/_slug.vue' /* webpackChunkName: "pages/bo-time-moments/_slug" */))
const _13541f45 = () => interopDefault(import('../pages/chickenverse/_slug.vue' /* webpackChunkName: "pages/chickenverse/_slug" */))
const _129c3fd0 = () => interopDefault(import('../pages/foundation/_slug.vue' /* webpackChunkName: "pages/foundation/_slug" */))
const _037d1b5b = () => interopDefault(import('../pages/franchising/_slug.vue' /* webpackChunkName: "pages/franchising/_slug" */))
const _63883c82 = () => interopDefault(import('../pages/menu/_mid/index.vue' /* webpackChunkName: "pages/menu/_mid/index" */))
const _7ff6f844 = () => interopDefault(import('../pages/news-and-community/_slug.vue' /* webpackChunkName: "pages/news-and-community/_slug" */))
const _00f24b36 = () => interopDefault(import('../pages/welcometobojangles/_slug.vue' /* webpackChunkName: "pages/welcometobojangles/_slug" */))
const _08b4f1e0 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7311cc54,
    name: "about"
  }, {
    path: "/account",
    component: _484494f6,
    name: "account"
  }, {
    path: "/biscuit-month",
    component: _e5333d36,
    name: "biscuit-month"
  }, {
    path: "/bo-time-moments",
    component: _37c1db88,
    name: "bo-time-moments"
  }, {
    path: "/careers",
    component: _3957697e,
    name: "careers"
  }, {
    path: "/expansion-menu",
    component: _0bea9cda,
    name: "expansion-menu"
  }, {
    path: "/in-the-community",
    component: _6a3618dc,
    name: "in-the-community"
  }, {
    path: "/media-resources",
    component: _7f6ea985,
    name: "media-resources"
  }, {
    path: "/menu",
    component: _5b71908c,
    name: "menu"
  }, {
    path: "/news-and-community",
    component: _7c89b5b4,
    name: "news-and-community"
  }, {
    path: "/offers",
    component: _2cb54b78,
    name: "offers"
  }, {
    path: "/order",
    component: _2c62ff52,
    name: "order"
  }, {
    path: "/positions",
    component: _42676b73,
    name: "positions"
  }, {
    path: "/refund",
    component: _4482e2e5,
    name: "refund"
  }, {
    path: "/search",
    component: _1fb125d6,
    name: "search"
  }, {
    path: "/technical-support",
    component: _9834cfb0,
    name: "technical-support"
  }, {
    path: "/about/chicken-scoop",
    component: _64bd03af,
    name: "about-chicken-scoop"
  }, {
    path: "/account/complete",
    component: _888cdd76,
    name: "account-complete"
  }, {
    path: "/account/inbox",
    component: _20eed6ac,
    name: "account-inbox"
  }, {
    path: "/account/login",
    component: _11ec380d,
    name: "account-login"
  }, {
    path: "/account/oauthcallback",
    component: _40619a00,
    name: "account-oauthcallback"
  }, {
    path: "/account/order-history",
    component: _2ebd308e,
    name: "account-order-history"
  }, {
    path: "/account/password-reset",
    component: _150c92a9,
    name: "account-password-reset"
  }, {
    path: "/account/payments",
    component: _90d3558e,
    name: "account-payments"
  }, {
    path: "/account/reset-password",
    component: _156e9b36,
    name: "account-reset-password"
  }, {
    path: "/account/settings",
    component: _17f8d1a2,
    name: "account-settings"
  }, {
    path: "/account/signup",
    component: _77f4b6a4,
    name: "account-signup"
  }, {
    path: "/account/verify",
    component: _58d33585,
    name: "account-verify"
  }, {
    path: "/app/confirm",
    component: _2fd77c50,
    name: "app-confirm"
  }, {
    path: "/app/payment",
    component: _77bc7a1e,
    name: "app-payment"
  }, {
    path: "/app/signin",
    component: _fdb32e8c,
    name: "app-signin"
  }, {
    path: "/expansion-menu/items",
    component: _21a7f56a,
    name: "expansion-menu-items"
  }, {
    path: "/menu/nutrition",
    component: _7af3a15c,
    name: "menu-nutrition"
  }, {
    path: "/menu/product",
    component: _497044ae,
    name: "menu-product"
  }, {
    path: "/messaging/test",
    component: _519af2a7,
    name: "messaging-test"
  }, {
    path: "/order/checkout",
    component: _c7a1a85e,
    name: "order-checkout"
  }, {
    path: "/order/confirm",
    component: _98d05ff6,
    name: "order-confirm"
  }, {
    path: "/order/location",
    component: _4186aec0,
    name: "order-location"
  }, {
    path: "/order/product",
    component: _462806b4,
    name: "order-product"
  }, {
    path: "/order/type",
    component: _5f987576,
    name: "order-type"
  }, {
    path: "/positions/info",
    component: _5723fbc6,
    name: "positions-info"
  }, {
    path: "/about/chicken-scoop/:slug?",
    component: _63066267,
    name: "about-chicken-scoop-slug"
  }, {
    path: "/expansion-menu/items/:item",
    component: _6ae2806b,
    name: "expansion-menu-items-item"
  }, {
    path: "/about/:slug",
    component: _767f0ee4,
    name: "about-slug"
  }, {
    path: "/account/:slug",
    component: _468df3ae,
    name: "account-slug"
  }, {
    path: "/bo-time-moments/:slug?",
    component: _3b2f1e18,
    name: "bo-time-moments-slug"
  }, {
    path: "/chickenverse/:slug?",
    component: _13541f45,
    name: "chickenverse-slug"
  }, {
    path: "/foundation/:slug?",
    component: _129c3fd0,
    name: "foundation-slug"
  }, {
    path: "/franchising/:slug?",
    component: _037d1b5b,
    name: "franchising-slug"
  }, {
    path: "/menu/:mid",
    component: _63883c82,
    name: "menu-mid"
  }, {
    path: "/news-and-community/:slug?",
    component: _7ff6f844,
    name: "news-and-community-slug"
  }, {
    path: "/welcometobojangles/:slug?",
    component: _00f24b36,
    name: "welcometobojangles-slug"
  }, {
    path: "/:slug?",
    component: _08b4f1e0,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
