export default (context, inject) => {
  const storyblok = {
    async getStories(type, page = 1, per_page = 100, excludes = null) {
      if (
        process.server &&
        process.env.NODE_ENV != 'development' &&
        process.env.LIVE_PREVIEW != 'true'
      ) {
        return await context.$storage.getFile(type)
      } else {
        const config = {
          starts_with: type,
          version: process.env.SB_VERSION || 'published',
          page,
          per_page
        }
        if (excludes) {
          config.excluding_slugs = excludes
        }
        return context.$storyapi
          .get('cdn/stories', config)
          .then((res) => {
            return res.data.stories
          })
          .catch((err) => {
            console.error(err)
            return null
          })
      }
    },
    async getStory(slug) {
      // When the site is running the build process for production
      if (process.server && process.env.NODE_ENV != 'development') {
        return await context.$storage.getFile(slug)
        // When the site is in development or set to draft
      } else if (
        process.env.NODE_ENV == 'development' ||
        process.env.LIVE_PREVIEW
      ) {
        return await context.$storyapi
          .get(`cdn/stories/${slug}`, {
            version: process.env.SB_VERSION || 'published'
          })
          .then((res) => {
            return res.data.story
          })
        // When the site is in production and running client side
      } else {
        return await context
          .$axios({
            method: 'get',
            url: `${window.location.origin}/_data/${slug}.json`
          })
          .then((res) => {
            return res.data
          })
      }
    }
  }

  inject('storyblok', storyblok)
  context.$storyblok = storyblok
}
