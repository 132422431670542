import Vue from 'vue'
import slugify from 'slugify'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'

Vue.mixin({
  decis(val) {
    const d = /^[-+]?[0-9]+\.[0-9]+$/
    if (val.value.match(d)) return val.substr(val.indexOf('.'))
    return val
  },
  formatAmnt(value) {
    const val = value / 1
    return val === null
      ? '[calculating]'
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(val * 100)
    // : `${val
    //     .toString()
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //     .toFixed(2)}`
  },
  even(number) {
    return number % 2 === 0
  },
  data() {
    return {
      today: new Date()
    }
  },
  filters: {
    phone(phone) {
      return phone
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    },
    formatAmnt(value) {
      const val = value / 1
      return val === null
        ? '[calculating]'
        : new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(val) // `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    },
    filename(str) {
      return str
        .split('\\')
        .pop()
        .split('/')
        .pop()
        .split('.')[0]
    }
  },
  methods: {
    shortenName(name) {
      return name.replace(/\s*\ - (.*?)$/, '')
    },
    formatName(name) {
      return name
        .toLowerCase()
        .replace(/\s*\ - (.*?)$/, '')
        .replace(/\'/g, '')
        .replace(/\’/g, '')
        .trim()
        .replace(/\s/g, '-')
    },
    dayPart(s) {
      s = s + ''
      s = s.split('- ')[1]
      if (s) {
        s = s.trim()
        return s
      }
      return false
    },
    encode(data) {
      if (!data) return
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
    },
    replicateArray(array, n) {
      // Create an array of size "n" with undefined values
      let arrays = Array.apply(null, new Array(n))

      // Replace each "undefined" with our array, resulting in an array of n copies of our array
      arrays = arrays.map(() => {
        return array
      })

      // Flatten our array of arrays
      return [].concat.apply([], arrays)
    },
    mainHeight() {
      // Full height less the banner and header
      if (process.browser) {
        // setTimeout(function() {
        // this.$nextTick(() => {
        let headerHeight = document.querySelector('header')
          ? document.querySelector('header').offsetHeight
          : 0
        let bannerHeight = document.querySelector('#banner')
          ? document.querySelector('#banner').offsetHeight
          : 0
        let footerHeight = document.querySelector('footer')
          ? document.querySelector('footer').offsetHeight
          : 0
        let height = window.innerHeight - bannerHeight - headerHeight
        return height
        // })
        // }, 100)
      }
    },
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    slugify(value) {
      if (!value) return ''
      const s = slugify(value, {
        remove: /[*+~`.,()/'"!:@]/g,
        replacement: '-', // replace spaces with replacement
        lower: true // result in lower case
      })
      return s
    },
    slugit(value) {
      let slug = ''
      // Change to lower case
      const titleLower = value.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')

      return slug
    },
    // eslint-disable-next-line no-unused-vars
    truncate(text, length, clamp) {
      text = text || ''
      clamp = clamp || '...'
      length = length || 30
      if (text.length <= length) return text

      let tcText = text.slice(0, length - clamp.length)
      let last = tcText.length - 1

      while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0])
        last -= 1

      // Fix for case when text dont have any `space`
      last = last || length - clamp.length

      tcText = tcText.slice(0, last)
      return tcText + clamp
    },
    even(number) {
      return number % 2 === 0
    },
    cleanIcon(text) {
      const val = text.replace(/fa-/g, '')
      // let tcText = val.replace(/-f/g, '')
      return val
    },
    filename(str) {
      return str
        .split('\\')
        .pop()
        .split('/')
        .pop()
        .split('.')[0]
    },
    resize(image, option) {
      const fileType = image.slice(-3)
      let endFile = ''
      const imageService = '//img2.storyblok.com/'
      const path = image.replace('//storyblok.pleinaircdn.com', '')
      const pathAb = path.replace('https:', '')

      endFile = fileType === 'svg' ? image : imageService + option + pathAb
      return endFile
    },
    fileType(filename) {
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined
    }
  },
  mounted() {
    this.$dayjs.extend(localizedFormat)
    this.$dayjs.extend(relativeTime)
    this.$dayjs.extend(isToday)
  }
})
