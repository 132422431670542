
  export default {
    data() {
      return {
        showModal: false
      }
    },

    
    computed: {
      modalContent() {
        return {

        }
      }
    },
    mounted() {

    },

    methods: {
        goToExternalLink(){
            this.$emit('updateModalValue'); 
        },

      close() {
        this.$cookiz.set("exit-catering", "opened");
        this.showModal = false;
      },

    }
  }
  