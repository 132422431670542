export default (context, inject) => {
  const meta = function(page, custom, productImage) {
    const basePath = 'https://www.bojangles.com'
    if(page) {
      let pageMeta = {
        title:
          page.content && page.content.meta_title
            ? page.content.meta_title
            : page.name,
        meta: [],
        script: [],
        link: [
          {
            rel: 'canonical',
            href: `${basePath}${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          }
        ]
      }

      if (!custom) { // Default
        pageMeta.meta.push(
          {
            hid: 'description',
            name: 'description',
            content: page.content.meta_description || process.env.npm_package_description
          },
          { 
            hid: 'og:title',
            name: 'title',
            property: 'og:title',
            content: page.content.meta_title || page.name
          },
          {
            hid: 'og:description',
            name: 'description',
            property: 'og:description',
            content: page.content.meta_description || process.env.npm_package_description
          },
          {
            hid: 'og:image',
            name: 'image',
            property: 'og:image',
            content: page.content.meta_image && page.content.meta_image.filename ? page.content.meta_image.filename : 'https://storyblok.pleinaircdn.com/f/110020/1200x687/3cfa19e877/og-image.png'
          },
          { 
            hid: 'og:url',
            name: 'url',
            property: 'og:url',
            content: `${basePath}${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image'
          }
        )
      } else { // Custom
        pageMeta.meta = custom
        pageMeta.meta.push(
          { 
            hid: 'og:url',
            name: 'url',
            property: 'og:url',
            content: `${basePath}${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          },
          { 
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image'
          }
        )
      }

      if(page.content.component == 'product') {
        // pageMeta.script.push({
        //   type: 'application/ld+json',
        //   json: {
        //     '@context': 'https://schema.org',
        //     '@type': 'Product',
        //     '@id': `${basePath}/`,
        //     name: page.name,
        //     description: page.content.description,
        //     sku: page.content.sku,
        //     image: productImage,
        //     url: `${basePath}/menu/items/${page.content.sku}`,
        //     productID: page.content.sku
        //   }
        // })
      }
      return pageMeta
    } else {
      return {
        title: 'Not Found'
      }
    }
  }

  inject('metadata', meta)
  context.$metadata = meta
}
