import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2f99ae1a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=2f99ae1a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/opt/build/repo/components/layout/Header.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default,Cart: require('/opt/build/repo/components/layout/Cart.vue').default,DoubleOrderConfirmation: require('/opt/build/repo/components/checkout/DoubleOrderConfirmation.vue').default,CateringExitPopup: require('/opt/build/repo/components/common/CateringExitPopup.vue').default})
